@charset "utf-8";


/*  Notosans CJK */

@font-face {
  font-family: 'Noto Sans Korean';
  font-style: normal;
  font-weight: 300;
  src: local("Noto Sans Light"), local("NotoSans-Light"), url(/assets/fonts/otoSansKR-Light-Hestia.eot), url(/assets/fonts/NotoSansKR-Light-Hestia.eot?#iefix) format("embedded-opentype"), url(/assets/fonts/NotoSansKR-Light-Hestia.woff) format("woff"), url(/assets/fonts/NotoSansKR-Light-Hestia.otf) format("opentype"); }

@font-face {
  font-family: 'Noto Sans Korean';
  font-style: normal;
  font-weight: 400;
  src: local("Noto Sans Medium"), local("NotoSans-Medium"), url(/assets/fonts/NotoSansKR-Regular-Hestia.eot), url(/assets/fonts/NotoSansKR-Medium-Hestia.eot?#iefix) format("embedded-opentype"), url(/assets/fonts/NotoSansKR-Medium-Hestia.woff) format("woff"), url(/assets/fonts/NotoSansKR-Medium-Hestia.otf) format("opentype"); }

@font-face {
  font-family: 'Noto Sans Korean';
  font-style: normal;
  font-weight: 700;
  src: local("Noto Sans Black"), local("NotoSans-Black"), url(/assets/fonts/NotoSansKR-Black-Hestia.eot), url(/assets/fonts/NotoSansKR-Black-Hestia.eot?#iefix) format("embedded-opentype"), url(/assets/fonts/NotoSansKR-Black-Hestia.woff) format("woff"), url(/assets/fonts/NotoSansKR-Black-Hestia.otf) format("opentype"); }



/* COMMON STYLE  Start */

body,div,p,span,table,th,td,ul,li,ol,dl,dt,dd,form,fieldset,input,textarea,select,td,th,a{margin:0;padding:0; font-family:'Noto Sans Korean','맑은 고딕', 'Malgun Gothic' , 'Dotum', 'Arial', 'Apple Gothic', sans-serif; letter-spacing:0px; word-wrap:break-word; font-weight: 300; }
body{margin:0;background-color:#fff;color:#707070;font-size:14px; letter-spacing:0px;-webkit-text-size-adjust:none;}
fieldset,img {border:0 none}
dl,ul,ol,menu,li {list-style:none}
blockquote, q {quotes: none;  margin:0; padding: 0;}
blockquote:before, blockquote:after,q:before, q:after {content:'';content:none;}
input,select,textarea,button {vertical-align:middle;}
input::-ms-clear {display:none;}
button{border:0 none;background-color:transparent;cursor:pointer;}
body,th,td,input,select,textarea,button { /*whitespace: nowrap;*/ word-break: break-all; line-height:1.5; }
h1,h2,h3,h4,h5 {margin: 0; padding: 0; font-weight: normal;}
a { text-decoration:none;}
a:active, a:hover, a:visited { text-decoration:none;}
table {border-collapse:collapse; border-spacing:0;}
.clfix:after{content:".";display:block;height:0;clear:both;visibility:hidden;font-size:0;}
.clfix{*display:inline-block;zoom:1;}

/* title */

/* btn */

.btn	{
	display: block; text-align: center; color: #fff; text-transform: uppercase;
	&.blue		{ background: #1d57dd; }
	&.white		{ background: #fff; color: #111; font-weight: 400; text-transform: capitalize; }
	&.big		{ padding: 13px 40px;  font-size: 15px; border-radius: 50px; margin: 0 auto; max-width: 200px; }
	&.medium	{ padding: 11px 40px; font-size:13px;  border-radius: 30px; }
	&.small		{ padding: 5px 10px;  font-size: 11px; border-radius: 5px; }
	&.blue-ghost	{ border:1px solid #1d57dd; border-radius: 25px; padding: 10px 30px; font-weight: 400; color:#1d57dd; }

}

/* etc */
.center-wrap	{ width:1011px; position: relative; margin: 0 auto;  }

/* header */
@mixin nav-on	{
	a	{
			&:link		{ color: $white; font-weight: 400; }
			&:visited	{ color: $white; font-weight: 400; }
			&:hover		{ color: $white; font-weight: 400; }
			&:active	{ color: $white; font-weight: 400; }
		}
}

@mixin scroll-nav-on	{
	a	{
			&:link		{ color: $blue; font-weight: 400; }
			&:visited	{ color: $blue; font-weight: 400; }
			&:hover		{ color: $blue; font-weight: 400; }
			&:active	{ color: $blue; font-weight: 400; }
		}
}





