@charset "utf-8";

$white: #fff;
$grey: #cdcdcd;
$grey-ligtest: #f7f7f7;
$blue-lightest: #7694e9;
$blue-light: #1d57dd;
$blue: #2046b3;

@import 'common.scss';

/* HEADER */

body { display:table; width:100%; min-width:1011px; }


/* GNB, Header */

header {
	height:300px; background: $blue; word-break: keep-all; 

	nav	{
		position: relative; height:74px; border-bottom: 1px solid rgb(255, 255, 255); border-bottom: 1px solid rgba(255, 255, 255, .2); min-width: 1011px;
		h1	{position: absolute; top:18px; left:30px;}
		ul	{
			display: inline-block; position: absolute; top:24px; right:0; font-size:16px;
			> li	{
				display: inline-block; margin: 0 65px 0 0;
					> a	{
						&:link		{color: $blue-lightest;}
						&:visited	{color: $blue-lightest;}
						&:hover		{color: $white;}
						&:active	{color: $white;}
					}
			}
		}
		p	{
			display: inline-block; position: absolute; top:27px; right:30px; font-size:13px;
			> a	{
					border:1px solid $white; border-radius: 25px; padding: 10px 30px; font-weight: 400;
					&:link		{ color: $white; }
					&:visited	{ color: $white; }
					&:hover		{ color: $white; }
					&:active	{ color: $white; }
			}
		}
	}
	
	nav.scroll-nav	{
		position: fixed; top:0px; height:50px; width: 100%;  background: $white; box-shadow: 0px 5px 15px 4px rgba(000, 000, 000, 0.07); z-index: 1; min-width: 1011px;
		h1	{position: absolute; top:9px; left:30px;}
		img	{ width:90%; height:90%; }
		ul	{
			display: inline-block; position: absolute; top:11px; right:120px; font-size:16px;
			> li	{
				display: inline-block; margin: 0 65px 0 0;
					> a	{
						&:link		{color: #888; }
						&:visited	{color: #888; }
						&:hover		{color: $blue-light; }
						&:active	{color: $blue-light; }
					}
			}
		}
		p	{
			display: inline-block; position: absolute; top:15px; right:30px; font-size:13px;
			> a	{
					border:1px solid $blue-light; border-radius: 25px; padding: 5px 15px; font-weight: 400;
					&:link		{ color: $blue-light; }
					&:visited	{ color: $blue-light; }
					&:hover		{ color: $blue-light; }
					&:active	{ color: $blue-light; }
			}
		}
	}
	
	div	{
		width:1240px; margin:0 auto; position: relative;
		h2 { position: absolute; top:53px; left: 0px;  color: $white; font-size: 65px; font-weight: 700; letter-spacing: -1px; }
	}
}


/* footer */

footer	{
	padding:0 30px; height: 78px; position: relative;
	font-size:12px; color:#999; vertical-align: middle; border-top:1px solid #dcdcdc;
	div 	{
		display: inline-block; position: absolute; top:20px; vertical-align: middle; 
		>	span	{ display: block; width: 310px; position: absolute; top:12px; left: 40px; }
	}
	> p	{
		position: absolute; top:30px; right: 30px;
		>	span	{ margin: 0 10px; vertical-align: top; color:#ededed ; font-size: 10px; }
	}
	
}

/* main */

#page-main	{
	
	header { height: 80vh; background: #233b81 url('/assets/images/main/bg-promotion.jpg') center center no-repeat; background-size:cover;  }
	
	.promotion-txt	{ 
		margin-top: 25vh; text-align: center; color: $white;
		h2	{ font-size: 75px; font-weight: 700; letter-spacing: -2px; }
		p	{ font-size: 20px; font-weight:300; margin:5px 0 30px 0; }
		a	{
			padding: 10px 50px; border:1px solid $white; border-radius: 25px; font-weight: 400; transition: .1s ease-out all;
			&:link 		{ color: $white; }
			&:visited 	{ color: $white; }
			&:hover 	{ color: $blue; background: $white; }
			&:active	{ color: $white; }
		}
	}

	.product	{
		text-align: center;
		h2	{
			color: #222; font-size:20px;
		}
		>	p	{ font-size: 14px; color: #888; }
		>	div	{  padding:100px 0;
			>	div	{
					margin:80px 0 0 0;
					>	dl	{
						display: inline-block;
						>	dt	{ display: block; font-size:18px;
							>	span	{ margin:15px 0 5px 0 ; display: block; color:#222; font-weight: 400; }
							img	{ width: 50%; height: 50%; }
						}
						>	dd	{display: block;}
					}
					>	dl:nth-child(1) { margin:0 170px 0 0; }
					>	dl:nth-child(3) { margin:0 0 0 170px; }
			}
		}
	}

	.main-whoweare	{
			height: 430px; background: $grey-ligtest; text-align: center;
			>	div	{
					width:1240px; height: 100%; position: relative; margin:0 auto; 
					>	dl	{ padding:140px 0 0 0 ;
							>	dt	{ margin:0 0 20px 0; color: $blue; font-size: 28px; font-weight: 700; text-align: left; }
							>	dd	{ color: #888; font-size: 15px; font-weight: 300; text-align: left; }
					}
			}
			ul	{
				position: absolute; top:92px; right:0px; width:382px; display: inline-block; text-align: left;
				>	li	{
					margin: 0 0 30px 0;
					
					>	div	{
						position: relative; height: 2px;  background: #e8e8e8; 
						>	span {  position: absolute; top:0px; left:0px; height: 2px; background: #1e58de; }
					}
					
					>	p	{
						margin:0 0 15px 0; font-size: 14px; color: #333; font-weight: 400; 
						>	span	{ float: right; }
					}
				}
				
			}
	}

	.main-project	{
		padding:100px 0; text-align: center;
		>	h2 { margin: 0 0 70px 0; color: #222; font-size:20px; }
		>	ul	{ 
				width:1304px; margin: 0 auto; font-size: 0px;
				>	li	{
					width: 310px; height: 275px; position: relative; display: inline-block; text-align: left;
					&:nth-child(1)	{ background: $blue url('/assets/images/page/bg_work16.jpg') center center no-repeat; background-size:110%; }
					&:nth-child(2)	{ background: $blue url('/assets/images/page/bg_work15.jpg') center center no-repeat; background-size:110%; }
					&:nth-child(3)	{ background: $blue url('/assets/images/page/bg_work01.jpg') center center no-repeat; background-size:110%; }
					&:nth-child(4)	{ background: $blue url('/assets/images/page/bg_work02.jpg') center center no-repeat; background-size:110%; }
					&:nth-child(5)	{ background: $blue url('/assets/images/page/bg_work03.jpg') center center no-repeat; background-size:110%; }
					&:nth-child(6)	{ background: $blue url('/assets/images/page/bg_work04.jpg') center center no-repeat; background-size:110%; }
					&:nth-child(7)	{ background: $blue url('/assets/images/page/bg_work05.jpg') center center no-repeat; background-size:110%; }
					&:nth-child(8)	{ background: $blue url('/assets/images/page/bg_work06.jpg') center center no-repeat; background-size:110%; }
					// &:nth-child(7)	{ background: $blue url('/assets/images/page/bg_work07.jpg') center center no-repeat; background-size:110%; }
					// &:nth-child(8)	{ background: $blue url('/assets/images/page/bg_work08.jpg') center center no-repeat; background-size:110%; }
					
					&:after	{
						display: block; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: $blue; opacity: 0; content: ""; z-index: 1; transition: .2s ease-out all;
					}

					&:hover {
						&:after	{ opacity: .8; }
					}
					>	a	{ position: absolute; left: 33px; bottom: 25px; z-index: 2; }
					>	dl {
						position: absolute; top:25px; left: 33px; z-index: 2;
						dt	{ font-size: 23px; font-weight: 400; color: $white; margin: 0 0 5px 0; }
						dd	{ font-size: 12px; font-weight: 300; color: $white; }
					}
				}
		}
	}
	
	.main-map	{
		display: table; width: 100%; height: 515px; vertical-align: middle; overflow: hidden;
		.left	{
			display: table-cell; position: relative; width: 50%; background: $blue; color: $white;
			>	dl	{
					 position: absolute; top:178px; right: 222px;
					>	dt	{ margin:0 0 12px 0; color: $white; font-size: 28px; font-weight: 700; }
					>	dd	{ line-height: 2em; }
			}
		}
		.right	{ 
			display: table-cell; width: 50%; background: #555;  background: #323432 url('/assets/images/common/bg_map.gif') center -390px no-repeat;
		}
	}
}



/* Product */

#page-pro-go	{
	
	header {
		nav ul  li.product	{
			@include nav-on
		}
	}
	header {
		nav.scroll-nav ul  li.product	{
			@include scroll-nav-on
		}
	}
		
	.product-menu	{ 
		height: 60px; vertical-align: middle; font-size: 16px; color: #666; text-align: center; border-bottom: 1px solid #dcdcdc;
		a	{ 
			display: inline-block; margin: 17px 83px; 
			&:link 	{ color: #666; }
			&:visited	{ color: #666; }
			&:hover 	{ color: $blue; }
			&:active	{ color: $blue; }
		}
	}
	.go	{ color: $blue;
		&:link 		{ color: $blue !important; font-weight: 400; }
		&:visited 	{ color: $blue !important; font-weight: 400; }
		&:hover 	{ color: $blue !important; font-weight: 400; }
		&:active	{ color: $blue !important; font-weight: 400; }
	}
	.product-ui	{
		padding: 150px 0px;
		h3	{ text-align: center; font-size: 38px; color: #222; font-weight: 300; }
		p	{ text-align: center; font-size: 18px; color: #888; }
		div	{
			position:relative; width: 1024px; height: 462px; margin: 50px auto; background: url('/assets/images/page/go-ui.jpg') center center no-repeat;
			a	{
				width: 46px; height: 46px;
				> dl	{
					display: inline-block; padding: 15px 20px;  width: 150px; height: 65px; border-radius: 5px; color: #1d57dd; font-size: 16px; font-weight: 400; background: $white; box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.09); margin: 0 0 0 15px; pointer-events: none;
					position: absolute; top:-25px; left: 40px; opacity: 0;
					transition: .2s ease-out; transform: translateX(-5px) scale(.95);
				}
				> dl	> dt	{ font-weight: 400; }
				> dl	> dd	{ font-size: 13px; color: #888; }
				&:hover	> dl	{ opacity: 1; transform: translateX(0px) scale(1); }

				&:nth-child(1)	{ position: absolute; top:83px; left:257px;  }
				&:nth-child(2)	{ position: absolute; top:41px; left:749px; }
				&:nth-child(3)	{ position: absolute; top:270px; left:365px;  }
			}
		}
	}
	.strength-go	{
		height: 350px; padding: 130px 0 0 0; background: $grey-ligtest; 
		dl	{
			height: 55px; margin: 0 0 20px 0; display: inline-block; 
			&:nth-child(1)	{ background:  url('/assets/images/page/ico-go1.gif') left 7px no-repeat; }
			&:nth-child(2)	{ width: 420px; float: right; background:  url('/assets/images/page/ico-go2.gif') left 7px no-repeat; }
			&:nth-child(3)	{ background:  url('/assets/images/page/ico-go3.gif') left 7px no-repeat; }
			&:nth-child(4)	{ width: 420px; float: right; background:  url('/assets/images/page/ico-go4.gif') left 7px no-repeat; }
			&:nth-child(5)	{ background:  url('/assets/images/page/ico-go5.gif') left 7px no-repeat; }
			&:nth-child(6)	{ width: 420px; float: right; background:  url('/assets/images/page/ico-go6.gif') left 7px no-repeat; }
			dt	{ margin: 0 0 0 60px; font-size: 21px; color: #222; }
			dd	{ margin: 0 0 0 60px; font-size: 14px; color: #888; }
		}
	}
	.pro-spec	{ 
		dl	{ 
			display: inline-block; width:144px; height: 207px; margin: 0 69px 0 0 ; padding:115px 0 145px 0; font-size: 0; vertical-align: top;
			&:last-child	{ margin-right:0px !important; }
			dt	{ 
				margin: 0 0 10px 0 ; font-size: 16px; color: #333; font-weight: 400; 
				span	{ margin:0 0 15px 0; display: block; color: #eeeeee; font-size: 63px; font-weight: 700; }
			}
			dd	{ height: 88px; font-size: 14px; color: #888; background:  url('/assets/images/page/bg-pro-spec.gif') left bottom no-repeat; }
		}
	}
	.brochure	{
		color:$white; background: #3e3e3e;
		dl	{ padding: 50px 0;
			dt	{
				margin: 0 0 10px 0; font-size: 19px;
			}
			dd	{
				font-size: 13px;
				color: #a2a2a2;
			}
		}
		a	{ display: block; position: absolute; top:58px; right: 0px; }
	}
	
}


#page-pro-server	{
	
	header {
		nav ul  li.product	{
			@include nav-on
		}
	}
	
	
	header {
		nav.scroll-nav ul  li.product	{
			@include scroll-nav-on
		}
	}
	
	.product-menu	{
		height: 60px; vertical-align: middle; font-size: 16px; color: #666; text-align: center; border-bottom: 1px solid #dcdcdc;
		a	{
			display: inline-block; margin: 17px 83px;
			&:link 	{ color: #666; cursor: pointer; }
			&:visited	{ color: #666; }
			&:hover 	{ color: $blue; }
			&:active	{ color: $blue; }
		}
	}
	.server	{
		&:link 		{ color: $blue !important; font-weight: 400; }
		&:visited 	{ color: $blue !important; font-weight: 400; }
		&:hover 	{ color: $blue !important; font-weight: 400; }
		&:active	{ color: $blue !important; font-weight: 400; }
	}
	.product-ui	{
		padding: 150px 0px;
		h3	{ text-align: center; font-size: 38px; color: #222; font-weight: 300; }
		p	{ text-align: center; font-size: 18px; color: #888; }
		div	{
			position:relative; width: 1024px; height: 462px; margin: 50px auto; background: url('/assets/images/page/server-ui.jpg') center center no-repeat;
			a	{
				>	dl	{
							display: none; border-radius: 5px; color: #1d57dd; font-size: 16px; font-weight: 400; background: $white;
							box-shadow: 0px 3px 10px 3px rgba(000, 000, 000, 0.09); margin: 0 0 0 15px; position: absolute; top:-25px; left: 40px;
				}
				>	dl	>	dt	{ font-weight: 400; }
				>	dl	>	dd	{ font-size: 13px; color: #888; }
				&:hover	>	dl	{ display: inline-block; padding: 15px 20px;  width: 195px; height: 65px; }

				&:nth-child(1)	{ position: absolute; top:223px; left:237px;  }
				&:nth-child(2)	{ position: absolute; top:110px; left:526px; }
				&:nth-child(3)	{ position: absolute; top:356px; left:371px;  }
			}
		}
	}
	.strength-go	{
		height: 370px; padding: 130px 0 0 0; background: $grey-ligtest;
		dl	{
			height: 55px; margin: 0 0 84px 0; display: inline-block;
			&:nth-child(1)	{ background:  url('/assets/images/page/ico-server1.gif') left 7px no-repeat; }
			&:nth-child(2)	{ width: 485px; float: right; background:  url('/assets/images/page/ico-server2.gif') left 7px no-repeat; }
			&:nth-child(3)	{ background:  url('/assets/images/page/ico-server3.gif') left 7px no-repeat; }
			&:nth-child(4)	{ width: 485px; float: right; background:  url('/assets/images/page/ico-server4.gif') left 7px no-repeat; }
			dt	{ margin: 0 0 0 60px; font-size: 21px; color: #222; }
			dd	{ margin: 0 0 0 60px; font-size: 14px; color: #888; }
		}
	}
	.pro-spec	{
		dl	{
			display: inline-block; width:144px; height: 207px; margin: 0 69px 0 0 ; padding:115px 0 145px 0; font-size: 0; vertical-align: top; 
			&:last-child	{ margin-right:0px !important; }
			dt	{
				margin: 0 0 10px 0 ; font-size: 16px; color: #333; font-weight: 400; 
				span	{ margin:0 0 15px 0; display: block; color: #eeeeee; font-size: 63px; font-weight: 700; }
			}
			dd	{ height: 88px; font-size: 14px; color: #888; background:  url('/assets/images/page/bg-pro-spec.gif') left bottom no-repeat; }
		}
	}
	
}


#page-pro-storage	{
	
	header {
		nav ul  li.product	{
			@include nav-on
		}
	}
	
	header {
		nav.scroll-nav ul  li.product	{
			@include scroll-nav-on
		}
	}
	
	.product-menu	{
		height: 60px; vertical-align: middle; font-size: 16px; color: #666; text-align: center; border-bottom: 1px solid #dcdcdc;
		a	{
			display: inline-block; margin: 17px 83px;
			&:link 		{ color: #666; }
			&:visited	{ color: #666; }
			&:hover 	{ color: $blue; }
			&:active	{ color: $blue; }
		}
	}
	.storage	{
		&:link 		{ color: $blue !important; font-weight: 400; }
		&:visited 	{ color: $blue !important; font-weight: 400; }
		&:hover 	{ color: $blue !important; font-weight: 400; }
		&:active	{ color: $blue !important; font-weight: 400; }
	}
	.prepare	{
		height: 650px; text-align: center; margin: auto auto;
		img	{
			margin: 250px 0 0 0;
		}
	}
}


#page-business	{
	header {
		nav ul  li.business	{
			@include nav-on
		}
	}
	
	header {
		nav.scroll-nav ul  li.business	{
			@include scroll-nav-on
		}
	}
		
	.product	{
		text-align: center;
		h2	{
			color: #222; font-size:20px;
		}
		>	p	{ font-size: 14px; color: #888; }
		>	div	{  padding:100px 0;
			>	div	{
					margin:80px 0 0 0;
					>	dl	{
						display: inline-block; vertical-align: top;
						>	dt	{ display: block; font-size:18px;
								>	span	{ margin:15px 0 5px 0 ; display: block; color:#222; font-weight: 400; }
						}
						>	dd	{display: block;}
					}
					>	dl:nth-child(1) { margin:0 47px 0 0; }
					>	dl:nth-child(2) { margin:0 47px 0 0; }
					>	dl:nth-child(3) { margin:0 47px 0 0; }
					>	dl:nth-child(4) { margin:0 47px 0 0; }
					>	dl:nth-child(5) { margin:0 0px 0 0; }
			}
			>	a	{ margin-top: 55px; }
		}
	}
	.contact	{
		padding:120px 0; background: $grey-ligtest;
		dl	{
			display: inline-block;
			&:last-child	{ float: right;}
			dt	{
				margin: 0 0 20px 0; padding: 0 0 17px 0; font-size: 34px; color: #222; letter-spacing: -1px; background:  url('/assets/images/page/bg-pro-spec.gif') 3px bottom no-repeat;
			}
			dd	{
				font-size: 15px; color: #888;
				p	{ margin: 20px 0 0 0; font-size: 23px; color: #1d57dd; font-style: italic; }
			}
		}
	}
}


#page-aboutus	{
	
	header {
		nav ul  li.aboutus	{
			@include nav-on
		}
	}
	
	header {
		nav.scroll-nav ul  li.aboutus	{
			@include scroll-nav-on
		}
	}
	
	.contact	{
		padding:120px 0; background: $grey-ligtest;
		dl	{
			display: inline-block;
			&:last-child	{ float: right;}
			dt	{
				margin: 0 0 20px 0; padding: 0 0 17px 0; font-size: 34px; color: #222; letter-spacing: -1px; background:  url('/assets/images/page/bg-pro-spec.gif') 3px bottom no-repeat;
			}
			dd	{
				font-size: 15px; color: #888;
				p	{ margin: 20px 0 0 0; font-size: 23px; color: #1d57dd; font-style: italic; }
			}
		}
	}
	
	.whoweare	{ padding:100px 0;  background: $grey-ligtest;
		div	{ position: relative; height: 260px; background: url('/assets/images/page/bg_about.gif') center 70px no-repeat;
			dl	{
				display: inline-block; text-align: center;
				dt	{ margin: 0 0 10px 0; font-size: 18px; color: #222; font-weight: 400; }
				dd	{ font-size: 14px; color: #666; }
			}
			>	dl:nth-child(1) { position:absolute; bottom:0px; left:0px; }
			>	dl:nth-child(2) { position:absolute; bottom:0px; left:390px; }
			>	dl:nth-child(3) { position:absolute; bottom:0px; right:0px; }
		}
		h3	{ text-align: center; font-size: 38px; color: #222; font-weight: 300; }
		p	{ text-align: center; font-size: 18px; color: #888; }
	}
	.timeline	{ padding:100px 0;	background: url('/assets/images/page/bg_timeline.gif')center center repeat-y;
		> .center-wrap	{ 
			height: 1125px;
			> div	{
				width:430px; padding:25px; position: relative; background: $grey-ligtest;
				p:nth-child(1)	{ font-size:15px; font-weight: 400; color:$blue-light; }
				p:nth-child(2)	{ font-size:21px; color:#222; }
				p:nth-child(3)	{ font-size:15px; color:#888; }

				&:nth-child(1)	{ position: relative; top:0px; left:0px; }
				&:nth-child(2)	{ position: relative; top:-50px; left:531px; }
				&:nth-child(3)	{ position: relative; top:-100px; left:0px; }
				&:nth-child(4)	{ position: relative; top:-150px; left:531px; }
				&:nth-child(5)	{ position: relative; top:-200px; left:0px; }
				&:nth-child(6)	{ position: relative; top:-250px; left:531px; }
				&:nth-child(7)	{ position: relative; top:-300px; left:0px; }
				&:nth-child(8)	{ position: relative; top:-350px; left:531px; }
				&:nth-child(9)	{ position: relative; top:-400px; left:0px; }
				&:nth-child(10)	{ position: relative; top:-450px; left:531px; }
				&:nth-child(11)	{ position: relative; top:-500px; left:0px; }
				&:nth-child(12)	{ position: relative; top:-550px; left:531px; }

				&:nth-child(2n-1)	{ 
					.poniter	{ 
						position: absolute; top:50%; right:-11px; margin-top:-5px; width: 0; height: 0; border-top: 9px solid transparent; border-left: 13px solid $grey-ligtest; border-bottom: 9px solid transparent;
						>	span	{ display: block; width: 8px; height: 8px; margin:-5px 0 0 11px; background:$blue-light;-moz-border-radius: 50px; -webkit-border-radius: 50px; border-radius: 50px; }
					}
				}
				&:nth-child(2n)		{
					.poniter	{
						position: absolute; top:50%; left:-10px; margin-top:-5px; width: 0; height: 0; border-top: 9px solid transparent; border-right: 13px solid $grey-ligtest; border-bottom: 9px solid transparent;
						>	span	{ display: block; width: 8px; height: 8px; margin:-5px 0 0 -19px; background:$blue-light;-moz-border-radius: 50px; -webkit-border-radius: 50px; border-radius: 50px; }
					}
				}
			}
		}
	}
	.map	{ height: 490px; background: #323432 url('/assets/images/common/bg_map.gif') center -410px no-repeat; }
	
}

ul.projects	{
	margin:40px 0; position: relative; overflow: hidden; transition: .5s ease-in height;
	> li	{
		transition: .3s ease-in all; position: absolute;
		dl	{ position: relative; z-index: 2;}
		> ul	{ opacity: 0; transition: .2s cubic-bezier(.03,.66,.22,1.25) all; transform: translateY(10px); position: relative; z-index: 2; }
		&:after	{ background: #2046b3; display: block; opacity: 0; content: ""; position: absolute; top: 0; width: 0; width: 100%; height: 100%; transition: .1s linear opacity; }
		&:hover	{
			&:after	{ opacity: .8; }
			> ul	{ opacity: 1; transform: translateY(0); }
		}
	}
	
	&.date	{
		height: 2350px;
		li.project	{ opacity: 0; z-index: 0;}
		li.time		{ opacity: 1; z-index: 1;}
	}
	
	&.project	{
		height: 4067px;
		li.project	{ opacity: 1; z-index: 1;}
		li.time		{ opacity: 0; z-index: 0;}
	}
	
	> li.project	{
		display: inline-block; height: 501px; width: 501px; margin:0 0 10px 0; background: #cdcdcd;
		> ul	{
			padding:30px 0 0 0; top:70px; left:45px; color: $white; font-size: 14px; background: url('/assets/images/page/bg-white_bar.gif') 0 0 no-repeat;
			li	{ line-height: 1.8em; }
		}
		dl	{
			position: relative; top:40px; left:45px; color: $white;
			dt	{ font-size:45px; font-weight: 700; letter-spacing: -1px; line-height: 1em; width: 80%; }
			dd	{ font-size:18px; font-weight:300; margin: 15px 0 0 0; }
		}
	}
	
	
	
	&.item	{}

	.project		{ }
	.project.item1	{ position:absolute; top:0px; 	 left:0px; 	 background: url('/assets/images/page/bg_work16.jpg') center center no-repeat; background-size:110%; }
	.project.item2	{ position:absolute; top:0px; 	 left:510px; 	 background: url('/assets/images/page/bg_work14.jpg') center center no-repeat; background-size:110%; }
	.project.item3	{ position:absolute; top:510px; 	 left:0px; 	 background: url('/assets/images/page/bg_work15.jpg') center center no-repeat; background-size:110%; }
	.project.item4	{ position:absolute; top:510px; 	 left:510px; 	 background: url('/assets/images/page/bg_work01.jpg') center center no-repeat; background-size:110%; }
	.project.item5	{ position:absolute; top:1020px; 	 left:0px; background: url('/assets/images/page/bg_work02.jpg') center center no-repeat; background-size:110%; }
	.project.item6	{ position:absolute; top:1020px;  left:510px; 	 background: url('/assets/images/page/bg_work03.jpg') center center no-repeat; background-size:110%; }
	.project.item7	{ position:absolute; top:1530px;  left:0px; background: url('/assets/images/page/bg_work04.jpg') center center no-repeat; background-size:110%; }
	.project.item8	{ position:absolute; top:1530px; left:510px; 	 background: url('/assets/images/page/bg_work05.jpg') center center no-repeat; background-size:110%; }
	.project.item9	{ position:absolute; top:2040px; left:0px; background: url('/assets/images/page/bg_work06.jpg') center center no-repeat; background-size:110%; }
	.project.item10	{ position:absolute; top:2040px; left:510px; 	 background: url('/assets/images/page/bg_work07.jpg') center center no-repeat; background-size:110%; }
	.project.item11	{ position:absolute; top:2550px; left:0px; background: url('/assets/images/page/bg_work08.jpg') center center no-repeat; background-size:110%; }
	.project.item12	{ position:absolute; top:2550px; left:510px; 	 background: url('/assets/images/page/bg_work09.jpg') center center no-repeat; background-size:110%; }
	.project.item13	{ position:absolute; top:3060px; left:0px; background: url('/assets/images/page/bg_work10.jpg') center center no-repeat; background-size:110%; }
	.project.item14	{ position:absolute; top:3060px; left:510px; 	 background: url('/assets/images/page/bg_work11.jpg') center center no-repeat; background-size:110%; }
	.project.item15	{ position:absolute; top:3570px; left:0px; background: url('/assets/images/page/bg_work12.jpg') center center no-repeat; background-size:110%; }
	.project.item16	{ position:absolute; top:3570px; left:510px; 	 background: url('/assets/images/page/bg_work13.jpg') center center no-repeat; background-size:110%; }


	> li.time	{
		display: block;  position: relative; height: 245px; width: 245px; margin:0 0 10px 0; background: #cdcdcd; vertical-align: top;
		>  ul	{
			position: relative; color: $white; font-size: 14px; background: url('/assets/images/page/bg-white_bar.gif') -17px 0 no-repeat;
			li	{ line-height: 1.8em; word-wrap: break-word; word-break: keep-all; }
		}

		dl	{
			position: relative; top:25px; left:30px; color: $white;
			dt	{ font-size:21px; font-weight: 400; line-height: 1em; width: 80%; } 
			dt	> span	{ display: block;  font-size:20px; font-weight: 400; }
			dd	{ font-size:12px; font-weight:300; margin: 15px 0 0 0; }
		}
	}

	.time			{}
	.time.item1		{
		height: 501px; width: 501px;
		position:absolute; top:0px; left:0px; background-size:110%; 
		dl	{  top:35px; left:40px; }
		dt	{ font-size:39px; font-weight: 400; letter-spacing: -1px; line-height: 1em; width: 80%; }
		dt	> span	{ display: block;  font-size:35px; font-weight: 400; word-break: keep-all;}
		dd	{ font-size:14px; font-weight:300; margin: 15px 0 0 0; }
		ul	{ top:70px; left:40px; padding-top:30px;  width:80%; }
	}
	.time.item2		{ position:absolute; top:0px; 	 left:511px; background-size:110%; ul { width:75%; top:45px; left:30px; padding-top:15px; } }
	.time.item3		{ position:absolute; top:0px; 	 left:766px; background-size:110%; ul { width:75%; top:45px; left:30px; padding-top:15px; } }
	.time.item4		{ position:absolute; top:256px;  left:511px; background-size:110%; ul { width:75%; top:45px; left:30px; padding-top:15px; } }
	.time.item5		{ position:absolute; top:256px;  left:766px; background-size:110%; ul { width:75%; top:45px; left:30px; padding-top:15px; } }

	.time.item6		{ position:absolute; top:511px;  left:0px;   background-size:110%; ul { width:75%; top:45px; left:30px; padding-top:15px; } }
	.time.item7		{ position:absolute; top:511px;  left:255px; background-size:110%; ul { width:75%; top:45px; left:30px; padding-top:15px; } }
	.time.item8		{ position:absolute; top:511px;  left:511px; background-size:110%; ul { width:75%; top:45px; left:30px; padding-top:15px; } }
	.time.item9		{ position:absolute; top:511px;  left:766px; background-size:110%; ul { width:75%; top:45px; left:30px; padding-top:15px; } }

	.time.item10	{ position:absolute; top:766px;  left:0px;   background-size:110%; ul { width:75%; top:45px; left:30px; padding-top:15px; } }
	.time.item11	{ position:absolute; top:766px;  left:255px; background-size:110%; ul { width:75%; top:45px; left:30px; padding-top:15px; } }
	.time.item12	{ position:absolute; top:766px;  left:511px; background-size:110%; ul { width:75%; top:45px; left:30px; padding-top:15px; } }
	.time.item13	{ position:absolute; top:766px;  left:766px; background-size:110%; ul { width:75%; top:45px; left:30px; padding-top:15px; } }

	.time.item14	{ position:absolute; top:1021px;  left:0px;   background-size:110%; ul { width:75%; top:45px; left:30px; padding-top:15px; } }
	.time.item15	{ position:absolute; top:1021px;  left:255px; background-size:110%; ul { width:75%; top:45px; left:30px; padding-top:15px; } }
	.time.item16	{ position:absolute; top:1021px;  left:511px; background-size:110%; ul { width:75%; top:45px; left:30px; padding-top:15px; } }
	.time.item17	{ position:absolute; top:1021px;  left:766px; background-size:110%; ul { width:75%; top:45px; left:30px; padding-top:15px; } }

	.time.item18	{ position:absolute; top:1276px;  left:0px;   background-size:110%; ul { width:75%; top:45px; left:30px; padding-top:15px; } }
	.time.item19	{ position:absolute; top:1276px;  left:255px; background-size:110%; ul { width:75%; top:45px; left:30px; padding-top:15px; } }
	.time.item20	{ position:absolute; top:1276px;  left:511px; background-size:110%; ul { width:75%; top:45px; left:30px; padding-top:15px; } }
	.time.item21	{ position:absolute; top:1276px;  left:766px; background-size:110%; ul { width:75%; top:45px; left:30px; padding-top:15px; } }

	.time.item22	{ position:absolute; top:1531px;  left:0px;   background-size:110%; ul { width:75%; top:45px; left:30px; padding-top:15px; } }
	.time.item23	{ position:absolute; top:1531px;  left:255px; background-size:110%; ul { width:75%; top:45px; left:30px; padding-top:15px; } }
	.time.item24	{ position:absolute; top:1531px;  left:511px; background-size:110%; ul { width:75%; top:45px; left:30px; padding-top:15px; } }
	.time.item25	{ position:absolute; top:1531px;  left:766px; background-size:110%; ul { width:75%; top:45px; left:30px; padding-top:15px; } }

	.time.item26	{ position:absolute; top:1786px;  left:0px; background-size:110%; ul { width:75%; top:45px; left:30px; padding-top:15px; } }
	.time.item27	{ position:absolute; top:1786px;  left:255px; background-size:110%; ul { width:75%; top:45px; left:30px; padding-top:15px; } }
	.time.item28	{ position:absolute; top:1786px;  left:511px; background-size:110%; ul { width:75%; top:45px; left:30px; padding-top:15px; } }
	.time.item29	{ position:absolute; top:1786px;  left:766px; background-size:110%; ul { width:75%; top:45px; left:30px; padding-top:15px; } }


	li.cmp	{ background: url('/assets/images/page/bg_work16.jpg') center center no-repeat; }
	li.smc	{ background: url('/assets/images/page/bg_work14.jpg') center center no-repeat; }
	li.miip	{ background: url('/assets/images/page/bg_work15.jpg') center center no-repeat; }
	li.video-cloud	{ background: url('/assets/images/page/bg_work01.jpg') center center no-repeat; }
	li.pixcam		{ background: url('/assets/images/page/bg_work02.jpg') center center no-repeat; }
	li.bass			{ background: url('/assets/images/page/bg_work03.jpg') center center no-repeat; }
	li.cloud-db		{ background: url('/assets/images/page/bg_work04.jpg') center center no-repeat; }
	li.tms			{ background: url('/assets/images/page/bg_work05.jpg') center center no-repeat; }
	li.game-cast	{ background: url('/assets/images/page/bg_work06.jpg') center center no-repeat; }
	li.galaxy		{ background: url('/assets/images/page/bg_work07.jpg') center center no-repeat; }
	li.file-manager { background: url('/assets/images/page/bg_work09.jpg') center center no-repeat; }
	li.computing	{ background: url('/assets/images/page/bg_work10.jpg') center center no-repeat; }
	li.block		{ background: url('/assets/images/page/bg_work11.jpg') center center no-repeat; }
	li.big-data		{ background: url('/assets/images/page/bg_work12.jpg') center center no-repeat; }
	li.cloud-storage{ background: url('/assets/images/page/bg_work08.jpg') center center no-repeat; }
	li.vdi-storage	{ background: url('/assets/images/page/bg_work13.jpg') center center no-repeat; }
}

#page-work	{
	header {
		nav ul  li.work	{
			@include nav-on
		}
	}
	
	header {
		nav.scroll-nav ul  li.work	{
			@include scroll-nav-on
		}
	}
	
	.stand_project	{
		.center-wrap	{ position: relative; margin-top:100px ;
			.sort	{ text-align: right; font-size:15px;
				a	{
					margin:0 0 0 20px; font-weight: 400; cursor: pointer;
					&:link,
					&:hover,
					&:visited,
					&:active	{ color:#666; }
					&.on	{ color:$blue-light; font-weight: 400; }
					&.on:hover,
					&.on:visited,
					&.on:active	{ color:$blue-light; font-weight: 400; }
				}
			}
		}
	}
}



















































